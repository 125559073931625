import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { AlertComponent } from "../../Components/AlertComponent";
import { LoadingComponent } from "../../Components/Loading";
import { ProductsGrid } from "../../Components/Products/Grid";
import routes from "../../Helpers/Routes.json";
import { Context } from "../../Shared/Context/GlobalContext";

export default function Homepage() {
  const {
    selectedCategory,
    categoriesLoading,
    categories,
    cartProducts,
    setSelectedCategory,
  } = useContext(Context);
  const params = useParams();
  const location = useLocation();

  let category = categories.find((i) => params.category === i.slug);

  if (!category) {
    category = categories[0];
  }

  useEffect(() => {
    setSelectedCategory(category);
  }, [location.pathname]);

  if (categoriesLoading) {
    return <LoadingComponent />;
  }

  if (!params.category) {
    return (
      <Navigate
        to={routes.Homepage.category.replace(":category", category.slug)}
        state={{ ...location.state }}
      />
    );
  }

  const prodQty = cartProducts.reduce((carry, { product }) => {
    return product.quantity + carry;
  }, 0);

  return (
    <Container className="pb-5">
      <Row>
        <Col>
          <h2 className="my-3 p-0 h6">{selectedCategory.name}</h2>
        </Col>
      </Row>
      <Row className="overflow-auto mh-100 d-flex justify-content-center pb-5">
        <ProductsGrid category={category} selectedCategory={selectedCategory} />
        <BottomAlert state={location.state} />

        {cartProducts.length ? (
          <div
            className="fixed-bottom mx-auto w-100 d-flex text-align-center"
            style={{ bottom: "70px" }}
          >
            <Link
              to={routes.OrderPage.index}
              className="d-flex alifn-items-center justify-content-center rounded rounded-pill shadow-sm mx-auto px-3 w-100 p-3 btn btn-outline-dark fw-bold bg-white text-dark"
            >
              <Trans
                i18nKey={"Comprar {{count}} {{name}}"}
                values={{
                  count: prodQty,
                  name: prodQty === 1 ? "producto" : "productos",
                }}
              />
            </Link>
          </div>
        ) : null}
      </Row>
    </Container>
  );
}

function BottomAlert({ state = {} }) {
  const [currentState, setCurrentState] = useState(state);
  useEffect(() => {
    setTimeout(() => {
      setCurrentState({});
    }, 3500);
  });

  if (currentState && currentState.product) {
    return (
      <AlertComponent variant={currentState.variant}>
        <span className="fw-bold">
          <Trans
            i18nKey={"{{name}} añadido al carrito"}
            values={{ name: currentState.product }}
          />
        </span>
      </AlertComponent>
    );
  }

  return null;
}
