import React, { useEffect, useState } from "react";
import { Alert, Carousel, Col, Container, Row } from "react-bootstrap";
import { AddToCartButton } from "../../../../../Components/AddToCartComponent";
import { LoadingComponent } from "../../../../../Components/Loading";
import { groupedProductValidator } from "../../../../../Helpers/validator";
import { Woo, createWooProduct } from "../../../../../Helpers/Woo";
import { VariableProductComponent } from "./__components/VariableProductComponent";
import { ProductCard } from "../../../../../Components/Products/Grid";
import { useTranslation } from "react-i18next";
import { StyledImageProduct } from "../../../style.css";
import parse from "html-react-parser";

export const GroupedProductLayout = ({ product }) => {
  const [bundledProducts, setBundledProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleAddProductToBundle = (data, remove = false) => {
    let products = [];
    let inCart = false;
    for (const prod of selectedProducts) {
      if (prod.product.id !== data.id) {
        products.push(prod);
      } else {
        inCart = true;
      }
    }

    if (remove === false && inCart === false) {
      products.push(createWooProduct(data));
    }

    setSelectedProducts(products);
  };

  useEffect(() => {
    Woo.products
      .findBy({
        include: product.grouped_products,
      })
      .then((products) => {
        const orderedProducts = [];
        const productsIndex = {};

        for (let product of products) {
          productsIndex[product.id] = product;
        }

        for (const groupedProductId of product.grouped_products) {
          orderedProducts.push(productsIndex[groupedProductId]);
        }

        setBundledProducts(orderedProducts);
        setLoading(false);
        const simpleProducts = [];
        for (const product of products) {
          if ("simple" === product.type) {
            simpleProducts.push(createWooProduct(product));
          }
        }
        setSelectedProducts(simpleProducts);
      });
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Carousel data-bs-theme="dark">
        {product.images.map((i) => {
          return (
            <Carousel.Item>
              <StyledImageProduct src={i.src} className="w-100 d-block" />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Container className="mt-3">
        <Row>
          <Col>
            <h2>{t(product.name)}</h2>
            <p>{product.price}€</p>
            <div>{parse(t(product.short_description))}</div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {bundledProducts.map((product) => {
            // todo add all product types
            if ("variable" === product.type) {
              return (
                <VariableProductComponent
                  product={product}
                  selectProduct={handleAddProductToBundle}
                  selectedProducts={selectedProducts}
                />
              );
            }

            if ("simple" === product.type) {
              return (
                <ProductCard
                  {...product}
                  link={false}
                  name={t(product.name)}
                  customCSS={`border-success`}
                />
              );
            }

            return null;
          })}
        </Row>
      </Container>
      {error && <Alert variant="danger">{error}</Alert>}
      <AddToCartButton
        originalProduct={product}
        product={product}
        bundledItems={selectedProducts}
        validator={groupedProductValidator}
        setError={setError}
      />
    </>
  );
};
