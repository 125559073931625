import { NavLink } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";

const slideIn = keyframes`
    0% {
    opacity: 0;
    transform: translate(-350px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0);
  }
`;

export const slideInAnimation = ({
  time = "1s",
  type = "ease",
  duration = "infinite",
} = {}) => css`
  animation: ${time} ${slideIn} ${type} ${duration};
`;

export const StyledCategory = styled(NavLink)`
  flex: 0 0 60px;
  max-width: 60px;
  min-height: 60px;
  text-align: center;
  text-decoration: none;
  ${(props) =>
    props.animate === "true" &&
    slideInAnimation({ time: "1s", type: "ease-in-out", duration: "0s" })};

  &[aria-current="page"] {
    font-weight: bold;
    & div {
      border-color: black;
    }
  }

  & div {
    height: 60px;
    width: 100%;
    border-radius: 50%;
    border: 1px solid silver;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    background-color: white;

    & img {
      max-width: 60%;
      max-height: 70%;
    }
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.9em;
    width: 100%;
    color: initial;
    position: relative;
  }

  margin: 0 14px;
`;

export const StyledCategoriesGrid = styled.div`
  width: 100vw;
  overflow-x: scroll;
  display: inline-flex;
  padding: 5px 0px 5px 0px;
`;
