import React, { createContext, useState } from "react";
import { useLocalStorage } from "../Hooks/useLocalStorage";

export const Context = createContext();

const Provider = ({ children, data }) => {
  const ls = useLocalStorage();
  const [user, setUser] = useState(() => {
    return ls.getJSON("user") ? ls.getJSON("user") : null;
  });
  const [cartProducts, addCartProducts] = useState(() => {
    return ls.getJSON("cartProducts") ? ls.getJSON("cartProducts") : [];
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [firstRender, isFirstRender] = useState(true);
  const [categories, setCategories] = useState([]);
  const [domain, setDomain] = useState(data);

  const value = {
    user,
    setUser: (data) => {
      setUser(data);
      ls.setJSON("user", data);
    },
    domain,
    setDomain,
    logout: () => {
      setUser(null);
      ls.remove("user");
    },
    addCartProducts: (data, action = "sum") => {
      updateCart(data, action);
    },
    resetCart: () => {
      ls.remove("cartProducts");
      addCartProducts([]);
    },
    removeCartProduct: (product) => {
      const filteredProducts = cartProducts.filter((i, key) => {
        return product !== key;
      });
      addCartProducts(filteredProducts);
      ls.setJSON("cartProducts", filteredProducts);
    },
    cartProducts,
    selectedCategory,
    setSelectedCategory: (category) => {
      setSelectedCategory(category);
    },
    categoriesLoading,
    setCategoriesLoading: (value) => {
      setCategoriesLoading(value);
    },
    productsLoading,
    setProductsLoading: (value) => {
      setProductsLoading(value);
    },
    categories,
    setCategories: (value) => {
      setCategories(value);
    },
    firstRender,
    isFirstRender: (value) => {
      isFirstRender(value);
    },
  };

  function updateCart(product, action) {
    console.log(action);
    let products = [];
    let update = false;
    cartProducts.forEach((prod) => {
      if (
        prod.product.id === product.product.id &&
        JSON.stringify(product.product) === JSON.stringify(prod.product)
      ) {
        switch (prod.product.type) {
          case "variable":
            if (prod.product.variation.id === product.product.variation.id) {
              if ("sum" === action) {
                prod.product.quantity++;
              } else {
                prod.product.quantity--;
              }
              update = true;
            }
            break;
          case "grouped":
            update = false;
            break;

          default:
            if ("sum" === action) {
              if (prod.product.quantity + 1 <= prod.product.maxStock) {
                prod.product.quantity++;
              }
            } else {
              prod.product.quantity--;
            }
            update = true;
            break;
        }
      }

      if (0 < prod.product.quantity) {
        products.push(prod);
      }
    });

    if (false === update) {
      products = [...cartProducts, product];
    }

    addCartProducts(products);
    ls.setJSON("cartProducts", products);
  }
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
