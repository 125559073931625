import React, { useState } from "react";
import { ModalComponent } from "../../../../../../../Components/Modal";
import { ProductCard } from "../../../../../../../Components/Products/Grid";
import { VariableProductPage } from "../../../VariableProductLayout";
import { useTranslation } from "react-i18next";

export const VariableProductComponent = ({ product, selectProduct }) => {
  const [show, setShow] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const { t } = useTranslation();

  function handleVariationSelection(variation) {
    if (variation) {
      setSelectedVariation({ ...product, variation: variation });
      selectProduct({ ...product, variation: variation });
    } else {
      setSelectedVariation(null);
      selectProduct({ ...product, variation: variation }, true);
    }
  }

  return (
    <>
      <ProductCard
        as={React.Fragment}
        {...product}
        cardClickCallback={() => {
          if (!selectedVariation) {
            setShow(true);
          } else {
            handleVariationSelection(null);
          }
        }}
        name={
          selectedVariation
            ? t(selectedVariation.variation.name)
            : t(product.name)
        }
        customCSS={selectedVariation ? `border-success` : null}
        textPrice={
          selectedVariation
            ? selectedVariation.variation.regular_price + "€"
            : null
        }
        cardCloseBtn={selectedVariation && <span>&times;</span>}
      />

      <ModalComponent show={show} setShow={setShow} title={t(product.name)}>
        <VariableProductPage
          product={product}
          setSelectedVariation={handleVariationSelection}
          setShow={setShow}
          isModal={true}
        />
      </ModalComponent>
    </>
  );
};
