import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Context } from "../../../../Shared/Context/GlobalContext";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../Shared/helpers/currency";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

export const CartListComponent = ({ cartProducts }) => {
  return cartProducts.map(({ product }, key) => {
    return <CartItemComponent key={key} index={key} product={product} />;
  });
};

export const CartItemComponent = ({ product, index }) => {
  const { removeCartProduct, addCartProducts } = useContext(Context);
  const { t } = useTranslation();

  let price = product.price;

  if (product.type === "bundle" || product.type === "grouped") {
    price = product.bundle.reduce((carry, item) => {
      if ("variable" === item.product.type) {
        return carry + item.product.variation.regular_price;
      } else {
        return carry + parseFloat(item.product.price);
      }
    }, 0);
  }

  return (
    <div className="bg-light my-3 rounded bordered p-1 px-2">
      <div className="d-flex justify-content-between small">
        <div className="flex-grow-1">
          {product.quantity}x{" - "}
          {product.type === "variable"
            ? t(product.variation.name)
            : t(product.name)}
        </div>
        <div className="d-flex text-end flex-column">
          {formatCurrency(price * product.quantity)}
          <div className="d-flex justify-content-around">
            <span
              className="p-1"
              onClick={() => {
                addCartProducts({ product: product }, "reduce");
              }}
            >
              <FiMinusCircle size={20} />
            </span>
            <span
              className="p-1"
              onClick={() => {
                addCartProducts({ product: product });
              }}
            >
              <FiPlusCircle size={20} />
            </span>
          </div>
        </div>
      </div>
      {product.type === "bundle" ||
        (product.type === "grouped" && (
          <ul className="list-unstyled small text-muted">
            {product.bundle.map(({ product }) => {
              switch (product.type) {
                case "variable":
                  return (
                    <li>
                      {t(product.variation.name)}{" "}
                      {formatCurrency(product.variation.regular_price)}
                    </li>
                  );
                case "simple":
                  return (
                    <li>
                      {t(product.name)} {formatCurrency(product.price)}
                    </li>
                  );
                default:
                  return null;
              }
            })}
          </ul>
        ))}
      {product.answers && product.answers.length ? (
        <ul>
          {product.answers.map(({ question, value }) => {
            return (
              <li>
                {t(question)} - {value}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
